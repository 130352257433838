import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import get from 'lodash/get';
import schemaQuery from '../../graphql/queries/schema.gql';
import MultiSelectDropdown from '../MultiSelectDropdown';

const FileIdentifierDropdown = ({
  filedProps,
  formName,
  isRequired,
  schemaId,
  versionId,
}) => {
  const fileIdentifierFieldName = `${
    formName ? `${formName}.` : ''
  }fileIdentifier`;

  const { data, loading } = useQuery(schemaQuery, {
    fetchPolicy: 'network-only',
    skip: !(schemaId && versionId), // Skip the query if schemaId or versionId is not available
    variables: { id: `${schemaId}:${versionId}` },
  });
  const schemaVersions = get(data, 'datasetSchema.versions', []);
  const schemaDefination = get(schemaVersions[0], 'definitions', []);

  const datumOptions = schemaDefination.map((definition) => {
    return {
      label: definition.fieldName,
      value: definition.fieldName, // Assuming value should be in lowercase
    };
  });
  return (
    <Field
      component={MultiSelectDropdown}
      data-cy={fileIdentifierFieldName}
      isLoading={loading}
      label={`File Identifier ${isRequired ? '*' : ''}`}
      name={fileIdentifierFieldName}
      options={datumOptions}
      validate={isRequired ? required : null}
      {...filedProps}
    />
  );
};

FileIdentifierDropdown.propTypes = {
  filedProps: PropTypes.shape({}),
  formName: PropTypes.string,
  isRequired: PropTypes.bool,
  schemaId: PropTypes.string,
  versionId: PropTypes.string,
};

FileIdentifierDropdown.defaultProps = {
  filedProps: {},
  formName: '',
  isRequired: false,
  schemaId: null,
  versionId: null,
};

export default FileIdentifierDropdown;
