import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import Input from '@shoreag/base/Input';
import { FieldArray } from 'react-final-form-arrays';
import Card from '@shoreag/base/Card';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import get from 'lodash/get';
import RemoveButton from '../../RemoveButton';
import AddButton from '../../AddButton';
import FlagHeading from '../../FlagHeading';
import getFileFormats from '../../../utilities/get-file-formats';
import { FILE_TYPE_OPTIONS } from '../../../utilities/constants';
import SchemaDropdown from '../../SchemaDropdown';
import PillsGroup from '../../PillsGroup';
import FileIdentifierDropdown from '../../FileIdentifierDropdown';

const colStyled = {
  flex: 'auto',
  maxWidth: ['100%', null, null, '100%'],
  mt: 0,
  pb: 4,
  width: '100%',
};

const FileSetupForm = ({
  children,
  formContext,
  formName,
  maxLimit,
  allowDefaultOne,
  ...sx
}) => {
  if (allowDefaultOne && !get(formContext.values, formName)) {
    formContext.form.change(formName, [{}]);
  }
  const wrapperStyled = {
    flex: 'auto',
    maxWidth: ['100%', null, null, '50%', '50%'],
    mb: 6,
    mt: 0,
    px: 4,
    width: '100%',
  };

  return (
    <Box sx={sx}>
      <FieldArray name={formName}>
        {({ fields }) => {
          const totalFields = fields.length;
          return (
            <>
              <Box as="h2" sx={{ mb: 4, mt: 6 }}>
                File Setup
              </Box>
              {totalFields > 0 ? (
                <>
                  {fields.map((name, index) => {
                    const fileFormat = get(
                      formContext.values,
                      `${name}.fileFormat`
                    );
                    const schemaId = get(
                      formContext.values,
                      `${name}.schemaId`
                    );
                    const versionId = get(
                      formContext.values,
                      `${name}.versionId`
                    );

                    const fileIdentifier =
                      get(formContext.values, `${name}.fileIdentifier`, []) ||
                      [];

                    return (
                      <Card
                        key={`${name}-${index}`}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mb: 5,
                          pb: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            mx: -4,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              mb: 5,
                              px: 4,
                              width: '100%',
                            }}
                          >
                            <FlagHeading color="accent" sx={{ mt: 0 }}>
                              File Setup
                              <Box
                                bg="accent"
                                borderRadius={0}
                                color="white"
                                ml={3}
                                px={2}
                                py="2px"
                              >
                                {index + 1}
                              </Box>
                            </FlagHeading>
                            {!(allowDefaultOne && totalFields === 1) ? (
                              <RemoveButton
                                onClick={() => fields.remove(index)}
                              >
                                Remove
                              </RemoveButton>
                            ) : null}
                          </Box>
                          <Field
                            component={Dropdown}
                            data-cy={`${name}.fileFormat`}
                            label="File Format *"
                            name={`${name}.fileFormat`}
                            options={getFileFormats()}
                            validate={required}
                            wrapperSx={{
                              ...wrapperStyled,
                              maxWidth: 'auto',
                              width: 'auto',
                            }}
                          />
                          {fileFormat === 'xml' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="XML Path *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                              }}
                            />
                          )}
                          {fileFormat === 'json' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="JSON Path *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                              }}
                            />
                          )}
                          {fileFormat === 'txt' && (
                            <Field
                              component={Input}
                              data-cy={`${name}.path`}
                              label="Delimiter *"
                              name={`${name}.path`}
                              validate={required}
                              wrapperSx={{
                                ...wrapperStyled,
                              }}
                            />
                          )}
                          <Field
                            component={Dropdown}
                            data-cy={`${name}.fileType`}
                            label="File Type *"
                            name={`${name}.fileType`}
                            options={FILE_TYPE_OPTIONS}
                            validate={required}
                            wrapperSx={{
                              ...wrapperStyled,
                            }}
                          />
                          <SchemaDropdown
                            filedProps={{
                              wrapperSx: { ...wrapperStyled },
                            }}
                            formContext={formContext}
                            formName={name}
                            hideSpinner
                            isRequired
                          />
                          <Box sx={colStyled}>
                            <FileIdentifierDropdown
                              formName={name}
                              isRequired
                              schemaId={schemaId}
                              versionId={versionId}
                            />
                            {fileIdentifier?.length > 0 && (
                              <PillsGroup
                                data={fileIdentifier.map((email) => ({
                                  label: email,
                                }))}
                                onRemove={(parameter) =>
                                  formContext.form.change(
                                    `${name}.fileIdentifier`,
                                    fileIdentifier.filter(
                                      (p) => p !== parameter.label
                                    )
                                  )
                                }
                                pillSx={{
                                  bg: 'accent',
                                }}
                                wrapperSx={{
                                  pl: 4,
                                  pt: 4,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Card>
                    );
                  })}
                  {(!maxLimit || totalFields < maxLimit) && (
                    <AddButton
                      data-cy="addFileSetup"
                      label="Add File setup"
                      onClick={() => fields.push({})}
                      wrapperSx={{ mt: 0 }}
                    />
                  )}
                </>
              ) : (
                <Card sx={{ display: 'flex', justifyContent: 'center' }}>
                  <AddButton
                    data-cy="addFileSetup"
                    label="Add File setup"
                    onClick={() => fields.push({})}
                  />
                </Card>
              )}
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

FileSetupForm.propTypes = {
  allowDefaultOne: PropTypes.bool,
  children: PropTypes.node,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }),
  formName: PropTypes.string.isRequired,
  maxLimit: PropTypes.number,
};

FileSetupForm.defaultProps = {
  allowDefaultOne: false,
  children: null,
  formContext: {},
  maxLimit: null,
};

export default FileSetupForm;
